import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Form,
} from 'reactstrap';
import {
    FaSortAlphaDown,
    FaSortAlphaUp,
    FaEdit,
    FaTimes,
    FaPlus,
    FaFilter,
    FaTimesCircle,
    FaCopy,
    FaTrash,
    FaArrowRight
} from 'react-icons/fa';
import sdk from 'api-sdk';
import userService from '../../services/user';
import RunHistoryModal from './runHistoryForCompaniesTable';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import {
    Menu,
    MenuItem,
    IconButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Define your color maps and options
const statusColorMap = {
    Pass: '#6AC93E',
    Fail: '#EA6565',
    'No defence': '#FFB300',
    'No defense': '#FFB300',
    Detected: '#2196F3',
    'No POC': '#9C27B0',
    'No Working': '#9E9E9E',
    Declined: '#FF9800',
};

const statusOrder = {
    Pass: 1,
    Fail: 2,
    'No defence': 3,
    'No defense': 3,
    Detected: 4,
    'No POC': 5,
    'No Working': 6,
    Declined: 7,
};

const defenceRulesColorMap = {
    Sysmon: '#FF9999', // Soft Red
    'CrowdStrike Falcon': '#FFB266', // Soft Orange
    'Microsoft Defender': '#BF360C', // Dark Orange
    'Carbon Black': '#2E7D32', // Dark Green
    SentinelOne: '#5C6BC0', // Medium Indigo
    Cynet: '#CC99FF', // Soft Purple
    Tanium: '#FF99CC', // Soft Pink
    Symantec: '#C62828', // Dark Red
    FireEye: '#1565C0', // Dark Blue
    McAfee: '#66CCFF', // Light Sky Blue
    'Bitdefender GravityZone': '#C299FF', // Medium Purple
    'Palo Alto Cortex': '#E64A19', // Deep Orange
    'Azure Sentinel': '#FF99FF', // Light Magenta
    'Aws Athena': '#F57C00', // Dark Orange
    GPO: '#8E24AA', // Dark Purple
};

const defenceRulesOptions = [
    'Sysmon',
    'CrowdStrike Falcon',
    'Microsoft Defender',
    'Carbon Black',
    'SentinelOne',
    'Cynet',
    'Tanium',
    'Symantec',
    'FireEye',
    'McAfee',
    'Bitdefender GravityZone',
    'Palo Alto Cortex',
    'Azure Sentinel',
    'Aws Athena',
    'GPO',
];

const categoryColorMap = {
    'Anti Forensics': '#6610f2',
    'C&C': '#9400D3',
    Collection: '#007BFF',
    Compile: '#4FC3F7',
    'Credential Access': '#20C997',
    'Data Exfiltration': '#28A745',
    'Defense Evasion': '#93C432',
    Discovery: '#67882F',
    Execution: '#FFC107',
    Impact: '#FD7E14',
    'Initial Access': '#F4A460',
    'Lateral Movement': '#FA8072',
    Misconfiguration: '#DC3545',
    Persistence: '#E83E8C',
    'Privilege Escalation': '#811840',
};

// Styled components
const StyledTable = styled(Table)`
  background-color: transparent !important;
  color: white !important;
  width: 100%;
  border: none !important;
  border-collapse: collapse;
  table-layout: fixed;
  /* Remove borders from thead, tbody, tr, th, and td */
  thead,
  tbody,
  tr,
  th,
  td {
    border: none !important;
  }

  /* Add borders back to td elements */
  td {
    border: 1px solid #7e7e7e !important;
    font-size: 14px;
    font-weight: normal;
  }

  /* Add left border to th elements except the first one */
  th {
    border-left: 1px solid #7e7e7e !important;
  }

  /* Remove all borders from the first th element */
  th:first-child {
    border: none !important;
  }

  /* Remove left border from first td in each row */
  td:first-child {
    border-left: none !important;
  }

  /* Remove right border from last td in each row */
  td:last-child {
    border-right: none !important;
  }
`;

const StyledTableRow = styled('tr')`
  &:hover {
    background-color: rgba(104, 107, 100, 0.2); // Slight hover effect
  }
  cursor: pointer;
`;

const StatusTag = styled('div')(({ bgcolor, expanded }) => ({
    display: 'inline-block',
    paddingLeft: '8px',
    borderRadius: '4px',
    margin: '2px',
    padding: '4px 8px',
    color: '#FFFFFF',
    backgroundColor: bgcolor,
    position: 'relative',
    cursor: 'pointer',
    transition: 'padding-right 0.3s ease',
    paddingRight: expanded ? '24px' : '8px',
    whiteSpace: 'nowrap', // Prevent text wrapping
    boxSizing: 'border-box',
    '& .status-icon-button': {
        marginRight: '2px',
        position: 'absolute',
        right: '-2px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: expanded ? 1 : 0,
        transition: 'opacity 0.3s ease',
    },
}));

const DefenceRuleTag = styled('div')(({ bgcolor, expanded }) => ({
    display: 'inline-block',
    paddingLeft: '8px',
    margin: '2px',
    borderRadius: '4px',
    color: '#FFFFFF',
    padding: '4px 8px',
    backgroundColor: bgcolor,
    position: 'relative',
    cursor: 'pointer',
    transition: 'padding-right 0.3s ease',
    paddingRight: expanded ? '24px' : '8px',
    whiteSpace: 'nowrap', // Prevent text wrapping
    boxSizing: 'border-box',
    '& .defence-rule-icon-button': {
        marginRight: '2px',
        position: 'absolute',
        right: '-2px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        opacity: expanded ? 1 : 0,
        transition: 'opacity 0.3s ease',
    },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    padding: '2px',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#333', // Dark background for the menu
        color: '#fff', // Light text color
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#fff', // Light text color
    '&:hover': {
        backgroundColor: '#444', // Slightly lighter background on hover
    },
}));

const TagMenuItem = styled(MenuItem)(({ bgcolor }) => ({
    color: '#fff',
    padding: '4px 8px',
    margin: '4px',
    borderRadius: '4px',
    backgroundColor: bgcolor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(1)', // Initial scale
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        backgroundColor: bgcolor,
        transform: 'scale(1.05)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
}));


const TransparentMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
    },
}));

const CompaniesTable = () => {
    const [companies, setCompanies] = useState({});
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [showCompanyDetails, setShowCompanyDetails] = useState(false); // New state
    const [modal, setModal] = useState(false);
    const [runModal, setRunModal] = useState(false);
    const [csvModal, setCsvModal] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [runHistory] = useState(null);
    const [bullets, setBullets] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedColumnKey, setSelectedColumnKey] = useState(null);
    const [contextMenu, setContextMenu] = useState({
        mouseX: null,
        mouseY: null,
        history: null,
    });
    const [filters, setFilters] = useState({});
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filterColumnKey, setFilterColumnKey] = useState(null);

    const userRoles = userService.getRoles();
    const isRootOrUploader = userRoles.includes('root') || userRoles.includes('uploader');
    const isRoot = userRoles.includes('root');

    const getStatusColor = (status) => statusColorMap[status] || 'black';

    useEffect(() => {
        fetchCompaniesAndHistories();
        fetchBullets();
    }, []);

    const fetchCompaniesAndHistories = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            const historiesData = await sdk.runHistory.getAll();
            const companyNamesSet = new Set(companiesData.map((company) => company.companyName));
            const filteredHistoriesData = historiesData.filter((history) =>
                companyNamesSet.has(history.companyName)
            );
            const groupedByCompany = filteredHistoriesData.reduce((acc, curr) => {
                (acc[curr.companyName] = acc[curr.companyName] || []).push(curr);
                return acc;
            }, {});

            companiesData.forEach((company) => {
                groupedByCompany[company.companyName] = {
                    id: company.companyID,
                    histories: groupedByCompany[company.companyName] || [],
                };
            });

            setCompanies(groupedByCompany);
        } catch (error) {
            console.error('Error fetching companies and run histories:', error);
        }
    };

    const fetchBullets = async () => {
        try {
            const bulletsData = await sdk.silverBullets.get();
            setBullets(bulletsData);
        } catch (error) {
            console.error('Error fetching bullets:', error);
        }
    };

    const handleCompanyClick = (companyName) => {
        setSelectedCompany(companyName);
        setShowCompanyDetails(true);
    };

    const handleBackToCompanyList = () => {
        setShowCompanyDetails(false);
        setSelectedCompany(null);
        // Reset filters and sortConfig if needed
        setFilters({});
        setSortConfig({ key: null, direction: 'asc' });
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const toggleRunModal = () => {
        setRunModal(!runModal);
    };

    const toggleCsvModal = () => {
        setCsvModal(!csvModal);
    };

    const handleNewCompanyChange = (e) => {
        setNewCompanyName(e.target.value);
    };

    const handleCreateCompany = async () => {
        try {
            await sdk.companies.create({ companyName: newCompanyName });
            setNewCompanyName('');
            toggleModal();
            await fetchCompaniesAndHistories();
            toast.success(`Company ${newCompanyName} created successfully`);
        } catch (error) {
            console.error('Error creating company:', error);
        }
    };

    const handleCsvUpload = async (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            complete: async (results) => {
                const jsonData = results.data;
                const existingHistories = await sdk.runHistory.getAll();

                jsonData.forEach(async (run) => {
                    const bullet = bullets.find((b) => normalizeString(b.name) === normalizeString(run.bulletName));
                    const runHistoryData = {
                        companyName: run.companyName,
                        categoryName: run.categoryName,
                        runDate: run.runDate,
                        notes: run.notes,
                        status: run.status,
                        runNumber: run.runNumber,
                        defenceRules: run.defenceRules,
                        bulletName: run.bulletName,
                        bulletId: bullet ? bullet.id : null,
                    };

                    const existingEntry = existingHistories.find(
                        (history) =>
                            history.companyName === runHistoryData.companyName &&
                            normalizeString(history.bulletName) === normalizeString(runHistoryData.bulletName)
                    );

                    if (existingEntry) {
                        await sdk.runHistory.update(existingEntry.id, runHistoryData);
                    } else {
                        await sdk.runHistory.create(runHistoryData);
                    }
                });

                toggleCsvModal();
                fetchCompaniesAndHistories();
            },
        });
    };

    function normalizeString(str) {
        return str.trim().replace(/\s+/g, ' ').toLowerCase();
    }

    const handleDeleteCompany = async (companyId, companyName) => {
        if (window.confirm(`Are you sure you want to delete the company ${companyName}?`)) {
            try {
                await sdk.companies.delete(companyId);
                fetchCompaniesAndHistories();
                toast.success(`Company ${companyName} deleted successfully`);
            } catch (error) {
                console.error('Error deleting company:', error);
            }
        }
    };

    const handleDeleteRunHistory = async (historyId) => {
        if (window.confirm('Are you sure you want to delete this run history?')) {
            try {
                await sdk.runHistory.delete(historyId);
                fetchCompaniesAndHistories();
                toast.success('Run History deleted successfully');
            } catch (error) {
                console.error('Error deleting run history:', error);
            }
        }
    };

    const handleSaveRunHistory = async (runHistoryData, runHistoryId) => {
        try {
            const existingHistories = await sdk.runHistory.getAll();
            const normalizeString = (str) => str.trim().toLowerCase();
            const existingEntry = existingHistories.find(
                (history) =>
                    normalizeString(history.companyName) === normalizeString(runHistoryData.companyName) &&
                    normalizeString(history.bulletName) === normalizeString(runHistoryData.bulletName)
            );

            if (existingEntry) {
                await sdk.runHistory.update(existingEntry.id, runHistoryData);
            } else {
                await sdk.runHistory.create(runHistoryData);
            }

            toggleRunModal();
            await fetchCompaniesAndHistories();
            toast.success('Run History created successfully');
        } catch (error) {
            console.error('Error saving run history:', error);
        }
    };

    const handleMenuOpen = (event, key) => {
        setAnchorEl(event.currentTarget);
        setSelectedColumnKey(key);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedColumnKey(null);
    };

    const handleSort = (key, direction) => {
        setSortConfig({ key, direction });
        handleMenuClose();
    };

    const handleRowContextMenu = (event, history) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            history: history,
        });
    };

    const handleContextMenuClose = () => {
        setContextMenu({ mouseX: null, mouseY: null, history: null });
    };

    const handleContextMenuCopy = () => {
        const history = contextMenu.history;
        navigator.clipboard.writeText(JSON.stringify(history));
        handleContextMenuClose();
        toast.success('Row data copied to clipboard.');
    };

    const handleContextMenuDelete = () => {
        const history = contextMenu.history;
        handleDeleteRunHistory(history.id);
        handleContextMenuClose();
    };

    const handleFilterClick = (columnKey) => {
        setFilterColumnKey(columnKey);
        setFilterText(filters[columnKey] || '');
        setFilterDialogOpen(true);
        handleMenuClose();
    };

    const handleApplyFilter = () => {
        setFilters({
            ...filters,
            [filterColumnKey]: filterText,
        });
        setFilterDialogOpen(false);
    };

    const handleClearFilter = (columnKey) => {
        setFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            delete newFilters[columnKey];
            return newFilters;
        });
        handleMenuClose();
    };

    const renderDropdownMenu = (columnKey, colName) => {
        return (
            <>
                <IconButton
                    onClick={(e) => handleMenuOpen(e, columnKey)}
                    disableRipple
                    sx={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#FFFFFF',
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                        },
                        '&:focus': {
                            outline: 'none',
                            boxShadow: 'none',
                        },
                        fontSize: '16px',
                        color: '#a5a5a5',
                    }}
                >
                    {colName}
                    {filters[columnKey] && <FaFilter style={{ marginLeft: '4px', color: 'orange' }} />}
                </IconButton>

                <StyledMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && columnKey === selectedColumnKey}
                    onClose={handleMenuClose}
                >
                    <StyledMenuItem onClick={() => handleSort(columnKey, 'asc')}>
                        <FaSortAlphaUp style={{ marginRight: '8px' }} />
                        Sort Ascending
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleSort(columnKey, 'desc')}>
                        <FaSortAlphaDown style={{ marginRight: '8px' }} />
                        Sort Descending
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={() => handleFilterClick(columnKey)}
                        sx={{ borderTop: '1px solid #7a7b7d' }}
                    >
                        <FaFilter style={{ marginRight: '8px' }} />
                        Filter
                    </StyledMenuItem>
                    {filters[columnKey] && (
                        <StyledMenuItem onClick={() => handleClearFilter(columnKey)}>
                            <FaTimesCircle style={{ marginRight: '8px' }} />
                            Clear Filter
                        </StyledMenuItem>
                    )}
                </StyledMenu>
            </>
        );
    };

    const filteredAndSortedHistories = (histories) => {
        if (!Array.isArray(histories)) return [];

        const filtered = histories.filter((history) => {
            return Object.keys(filters).every((key) => {
                if (!filters[key]) return true;
                const value = history[key];
                if (value === undefined || value === null) return false;

                if (key === 'defenceRules') {
                    const defenceRulesList = value.split(',').map((rule) => rule.trim().toLowerCase());
                    return defenceRulesList.some((rule) => rule.includes(filters[key].toLowerCase()));
                }

                if (key === 'runDate') {
                    const filterDate = filters[key]; // This is in 'YYYY-MM-DD' format
                    const historyDate = new Date(value).toISOString().split('T')[0]; // Get 'YYYY-MM-DD' format
                    return historyDate === filterDate;
                }

                return value.toString().toLowerCase().includes(filters[key].toLowerCase());
            });
        });

        if (!sortConfig.key) return filtered;

        const sorted = [...filtered].sort((a, b) => {
            if (sortConfig.key === 'status') {
                const compareA = statusOrder[a.status] || 7;
                const compareB = statusOrder[b.status] || 7;
                return sortConfig.direction === 'asc' ? compareA - compareB : compareB - compareA;
            }

            if (sortConfig.key === 'runDate') {
                return sortConfig.direction === 'asc'
                    ? new Date(a.runDate) - new Date(b.runDate)
                    : new Date(b.runDate) - new Date(a.runDate);
            }

            if (sortConfig.key === 'runNumber') {
                return sortConfig.direction === 'asc'
                    ? parseInt(a.runNumber, 10) - parseInt(b.runNumber, 10)
                    : parseInt(b.runNumber, 10) - parseInt(a.runNumber, 10);
            }

            const compareA = a[sortConfig.key].toString().toLowerCase();
            const compareB = b[sortConfig.key].toString().toLowerCase();
            if (compareA < compareB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (compareA > compareB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

        return sorted;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-GB');
    };

    const updateHistory = async (updatedHistory) => {
        try {
            await sdk.runHistory.update(updatedHistory.id, updatedHistory);

            setCompanies((prevCompanies) => {
                const newCompanies = { ...prevCompanies };
                const company = newCompanies[updatedHistory.companyName];
                if (company) {
                    const index = company.histories.findIndex((h) => h.id === updatedHistory.id);
                    if (index !== -1) {
                        company.histories[index] = updatedHistory;
                    }
                }
                return newCompanies;
            });

            toast.success('Updated successfully');
        } catch (error) {
            console.error('Error updating:', error);
            toast.error('Failed to update');
        }
    };

    // StatusCell Component
    const StatusCell = ({ history, updateHistory, isEditable }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [hover, setHover] = useState(false);

        const expanded = hover || Boolean(anchorEl);

        const handleMouseEnter = () => {
            setHover(true);
        };

        const handleMouseLeave = () => {
            setHover(false);
        };

        const handleIconClick = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        const handleStatusChange = async (newStatus) => {
            const updatedHistory = { ...history, status: newStatus };
            await updateHistory(updatedHistory);
            handleMenuClose();
        };

        return (
            <StatusTag
                bgcolor={getStatusColor(history.status)}
                expanded={expanded}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {history.status}
                {isEditable && (
                    <IconButtonStyled
                        size="small"
                        onClick={handleIconClick}
                        className="status-icon-button"
                        sx={{
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                            },
                            color: 'white',
                        }}
                    >
                        <FaEdit />
                    </IconButtonStyled>
                )}
                <TransparentMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{ disablePadding: true }}
                >
                    {Object.keys(statusColorMap).map((statusOption) => (
                        <TagMenuItem
                            key={statusOption}
                            bgcolor={getStatusColor(statusOption)}
                            onClick={() => handleStatusChange(statusOption)}
                        >
                            {statusOption}
                        </TagMenuItem>
                    ))}
                </TransparentMenu>
            </StatusTag>
        );
    };

    // DefenceRulesCell Component
    const DefenceRulesCell = ({ history, updateHistory, isEditable }) => {
        const [defenceRulesList, setDefenceRulesList] = useState(
            history.defenceRules ? history.defenceRules.split(',').map((rule) => rule.trim()) : []
        );
        const [anchorEl, setAnchorEl] = useState(null);

        useEffect(() => {
            setDefenceRulesList(history.defenceRules ? history.defenceRules.split(',').map((rule) => rule.trim()) : []);
        }, [history.defenceRules]);

        const handleRemoveRule = async (ruleToRemove) => {
            const updatedList = defenceRulesList.filter((rule) => rule !== ruleToRemove);
            setDefenceRulesList(updatedList);

            const updatedHistory = { ...history, defenceRules: updatedList.join(', ') };
            await updateHistory(updatedHistory);
        };

        const handleAddRuleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        const handleAddRule = async (ruleToAdd) => {
            if (!defenceRulesList.includes(ruleToAdd)) {
                const updatedList = [...defenceRulesList, ruleToAdd];
                setDefenceRulesList(updatedList);

                const updatedHistory = { ...history, defenceRules: updatedList.join(', ') };
                await updateHistory(updatedHistory);
            }
            handleMenuClose();
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    minHeight: '24px',
                }}
            >
                {defenceRulesList.map((rule) => (
                    <DefenceRuleTagComponent
                        key={rule}
                        rule={rule}
                        color={defenceRulesColorMap[rule] || 'gray'}
                        onRemove={isEditable ? () => handleRemoveRule(rule) : null}
                    />
                ))}
                {isEditable && (
                    <>
                        <IconButton
                            size="small"
                            onClick={handleAddRuleClick}
                            sx={{
                                marginLeft: '4px',
                                '&:focus': {
                                    outline: 'none',
                                    boxShadow: 'none',
                                },
                                color: 'white',
                            }}
                        >
                            <FaPlus />
                        </IconButton>
                        <TransparentMenu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            MenuListProps={{ disablePadding: true }}
                        >
                            {defenceRulesOptions.map((ruleOption) => (
                                <TagMenuItem
                                    key={ruleOption}
                                    bgcolor={defenceRulesColorMap[ruleOption] || 'gray'}
                                    onClick={() => handleAddRule(ruleOption)}
                                >
                                    {ruleOption}
                                </TagMenuItem>
                            ))}
                        </TransparentMenu>
                    </>
                )}
            </div>
        );
    };

    const DefenceRuleTagComponent = ({ rule, color, onRemove }) => {
        const [hover, setHover] = useState(false);
        const expanded = hover;

        const handleMouseEnter = () => {
            setHover(true);
        };

        const handleMouseLeave = () => {
            setHover(false);
        };

        return (
            <DefenceRuleTag
                bgcolor={color}
                expanded={expanded}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {rule}
                {onRemove && (
                    <IconButtonStyled
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemove();
                        }}
                        className="defence-rule-icon-button"
                        sx={{
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <FaTimes />
                    </IconButtonStyled>
                )}
            </DefenceRuleTag>
        );
    };

    // CommentCell Component
    const CommentCell = ({ history, updateHistory, isEditable }) => {
        const [open, setOpen] = useState(false);
        const [comment, setComment] = useState(history.notes || '');

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleCommentChange = (event) => {
            setComment(event.target.value);
        };

        const handleSaveComment = async () => {
            const updatedHistory = { ...history, notes: comment };
            await updateHistory(updatedHistory);
            handleClose();
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{history.notes}</span>
                {isEditable && (
                    <IconButton
                        size="small"
                        onClick={handleClickOpen}
                        style={{ marginLeft: '8px' }}
                        sx={{
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                            },
                            color: 'white',
                        }}
                    >
                        <FaEdit />
                    </IconButton>
                )}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Comment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Enter your comment below:</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="comment"
                            label="Comment"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            value={comment}
                            onChange={handleCommentChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSaveComment}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    if (showCompanyDetails && selectedCompany) {
        const companyHistories = companies[selectedCompany].histories;

        return (
            <div style={{ padding: '20px' }}>
                <Button onClick={handleBackToCompanyList} style={{ marginBottom: '30px' }}>
                    Back to Company List
                </Button>
                <h1>{selectedCompany}</h1>

                {/* Filter Dialog */}
                <Dialog open={filterDialogOpen} onClose={() => setFilterDialogOpen(false)}>
                    <DialogTitle>Filter</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {filterColumnKey === 'defenceRules'
                                ? 'Select a Defence Rule to filter by:'
                                : filterColumnKey === 'categoryName'
                                    ? 'Select a Category to filter by:'
                                    : filterColumnKey === 'status'
                                        ? 'Select a Status to filter by:'
                                        : filterColumnKey === 'runDate'
                                            ? 'Select a Date to filter by:'
                                            : `Enter text to filter the column "${filterColumnKey}".`}
                        </DialogContentText>

                        {/* Conditional rendering based on column type */}
                        {filterColumnKey === 'defenceRules' ? (
                            <Select
                                fullWidth
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => selected || <em>Select a Defence Rule</em>}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: 'transparent', // Transparent background for the entire Menu
                                            boxShadow: 'none', // Remove any shadow
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {defenceRulesOptions.map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            color: '#FFFFFF',
                                            backgroundColor: defenceRulesColorMap[option] || 'gray',
                                            '&:hover': {
                                                backgroundColor: defenceRulesColorMap[option] || 'gray',
                                                opacity: 0.9,
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: defenceRulesColorMap[option] || 'gray', // Selected color
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : filterColumnKey === 'categoryName' ? (
                            <Select
                                fullWidth
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => selected || <em>Select a Category</em>}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                        },
                                    },
                                }}
                                sx={{
                                    backgroundColor: 'transparent',
                                    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                                    '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Object.keys(categoryColorMap).map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            color: '#FFFFFF',
                                            backgroundColor: categoryColorMap[option] || 'gray',
                                            '&:hover': {
                                                backgroundColor: categoryColorMap[option] || 'gray',
                                                opacity: 0.8,
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: categoryColorMap[option] || 'gray',
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : filterColumnKey === 'status' ? (
                            <Select
                                fullWidth
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => selected || <em>Select a Status</em>}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                        },
                                    },
                                }}
                                sx={{
                                    backgroundColor: 'transparent',
                                    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                                    '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Object.keys(statusColorMap).map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            color: '#FFFFFF',
                                            backgroundColor: statusColorMap[option] || 'gray',
                                            '&:hover': {
                                                backgroundColor: statusColorMap[option] || 'gray',
                                                opacity: 0.8,
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: statusColorMap[option] || 'gray',
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : filterColumnKey === 'runDate' ? (
                            <TextField
                                label="Select Date"
                                type="date"
                                value={filterText || ''}
                                onChange={(e) => setFilterText(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        ) : (
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Filter Text"
                                type="text"
                                fullWidth
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleApplyFilter();
                                    }
                                }}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setFilterDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleApplyFilter}>Apply</Button>
                    </DialogActions>
                </Dialog>

                <StyledTable>
                    <colgroup>
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{renderDropdownMenu('bulletName', 'Bullet')}</th>
                            <th>{renderDropdownMenu('categoryName', 'Category')}</th>
                            <th>{renderDropdownMenu('status', 'Status')}</th>
                            <th>{renderDropdownMenu('runDate', 'RunDate')}</th>
                            <th>{renderDropdownMenu('runNumber', 'RunNumber')}</th>
                            <th>{renderDropdownMenu('defenceRules', 'Defence Rules')}</th>
                            <th>{renderDropdownMenu('notes', 'Comment')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAndSortedHistories(companyHistories).map((history) => (
                            <StyledTableRow
                                key={history.id}
                                onContextMenu={(event) => handleRowContextMenu(event, history)}
                            >
                                <td>{history.bulletName}</td>
                                <td>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: categoryColorMap[history.categoryName] || 'gray',
                                            marginRight: '5px',
                                        }}
                                    ></span>
                                    {history.categoryName}
                                </td>
                                <td>
                                    <StatusCell
                                        history={history}
                                        updateHistory={updateHistory}
                                        isEditable={isRootOrUploader}
                                    />
                                </td>
                                <td>{formatDate(history.runDate)}</td>
                                <td>{history.runNumber}</td>
                                <td>
                                    <DefenceRulesCell
                                        history={history}
                                        updateHistory={updateHistory}
                                        isEditable={isRootOrUploader}
                                    />
                                </td>
                                <td>
                                    <CommentCell
                                        history={history}
                                        updateHistory={updateHistory}
                                        isEditable={isRootOrUploader}
                                    />
                                </td>
                            </StyledTableRow>
                        ))}
                    </tbody>
                </StyledTable>

                <StyledMenu
                    open={contextMenu.mouseY !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu.mouseY !== null && contextMenu.mouseX !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={handleContextMenuCopy}>
                        <FaCopy style={{ marginRight: '8px' }} />
                        Copy
                    </StyledMenuItem>
                    {isRootOrUploader && (
                        <StyledMenuItem onClick={handleContextMenuDelete}>
                            <FaTrash style={{ marginRight: '8px', color: '#ff3b30' }} />
                            Delete
                        </StyledMenuItem>
                    )}
                    {contextMenu.history && (
                        <StyledMenuItem>
                            <Link
                                to={`silver-bullet-management/edit/${contextMenu.history.bulletId}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleContextMenuClose();
                                }}
                                style={{ textDecoration: 'none' }}
                                onMouseEnter={(e) => (e.target.style.color = '#dab1da')} // Change to desired hover color
                                onMouseLeave={(e) => (e.target.style.color = '')}
                            >
                                <FaArrowRight style={{ marginRight: '8px' }} />
                                Navigate to bullet
                            </Link>
                        </StyledMenuItem>
                    )}
                </StyledMenu>
            </div>
        );
    } else {
        return (
            <div className="table-container">
                <Button color="primary" onClick={toggleModal} style={{ marginBottom: '1rem' }}>
                    Add New Company
                </Button>
                <Button
                    color="secondary"
                    onClick={toggleRunModal}
                    style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                >
                    Add Run
                </Button>
                <Button
                    onClick={toggleCsvModal}
                    style={{ marginBottom: '1rem', marginLeft: '1rem', backgroundColor: '#217346' }}
                >
                    Add Run From CSV
                </Button>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Create New Company</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="companyName">Company Name</Label>
                            <Input
                                type="text"
                                id="companyName"
                                value={newCompanyName}
                                onChange={handleNewCompanyChange}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreateCompany}>
                            Create Company
                        </Button>{' '}
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <RunHistoryModal
                    isOpen={runModal}
                    toggle={toggleRunModal}
                    onSaveRunHistory={handleSaveRunHistory}
                    runHistory={runHistory}
                />
                <Modal isOpen={csvModal} toggle={toggleCsvModal}>
                    <ModalHeader toggle={toggleCsvModal}>Upload CSV File</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="csvFile">CSV File</Label>
                                <Input type="file" id="csvFile" accept=".csv" onChange={handleCsvUpload} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleCsvModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Table>
                    <thead>
                        <tr>
                            <th style={{ fontSize: '24px', color: '#FFFFFF' }}>Company Name</th>
                            <th className="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(companies).map((companyName) => (
                            <tr key={companyName}>
                                <td
                                    style={{ fontSize: '20px', color: '#FFFFFF', cursor: 'pointer' }}
                                    onClick={() => handleCompanyClick(companyName)}
                                >
                                    {companyName}
                                </td>
                                <td className="text-right">
                                    {isRoot && (
                                        <Button
                                            size="sm"
                                            style={{ marginLeft: '10px', backgroundColor: 'red' }}
                                            onClick={() =>
                                                handleDeleteCompany(companies[companyName].id, companyName)
                                            }
                                        >
                                            Delete Company
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
};

export default CompaniesTable;
