import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/Companies';
var companies = {
  create: function create(companyData) {
    return request('post', "/".concat(model), companyData);
  },
  getAll: function getAll() {
    return request('get', "/".concat(model));
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  update: function update(id, companyData) {
    return request('put', "/".concat(model, "/").concat(id), companyData);
  },
  getBulletsByCompanyAndRunNumber: function getBulletsByCompanyAndRunNumber(companyName, runNumber) {
    console.log(companyName);
    console.log(runNumber);
    return request('get', "/".concat(model, "/getBulletsByCompanyAndRunNumber?companyName=").concat(companyName, "&runNumber=").concat(runNumber));
  },
  getScenariosByCompanyAndRunNumber: function getScenariosByCompanyAndRunNumber(companyName, runNumber) {
    return request('get', "/".concat(model, "/getScenariosByCompanyAndRunNumber?companyName=").concat(companyName, "&runNumber=").concat(runNumber));
  },
  uploadCompanyImage: function uploadCompanyImage(id, formData) {
    // This function expects a FormData object with the image file
    return request('post', "/".concat(model, "/").concat(id, "/uploadImage"), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getCompanyImage: function getCompanyImage(id) {
    // Retrieve the image directly for display
    return requestDownload("/".concat(model, "/").concat(id, "/image"));
  },
  getCompanyImageBase64: function getCompanyImageBase64(id) {
    // Retrieve the image in Base64 format
    return request('get', "/".concat(model, "/").concat(id, "/image/base64"));
  }
};
export default companies;