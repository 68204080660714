import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/Scenarios';
var scenarios = {
  // Fetch all scenarios with an optional filter
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  // Fetch a scenario by ID with an optional filter
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  // Create a new scenario
  create: function create(scenario) {
    return request('post', "/".concat(model), scenario);
  },
  // Update an existing scenario by ID
  update: function update(id, scenario) {
    return request('patch', "/".concat(model, "/").concat(id), scenario);
  },
  // Delete a scenario by ID
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  }
};
export default scenarios;