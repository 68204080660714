import React from 'react';
import {
    Card,
    Col,
    Progress,
    Row,
    Button,
} from 'reactstrap';
import { Pie } from 'react-chartjs-2';
import vectors from '../../assets/data/attackVectors.json';
import sdk from 'api-sdk';

const labels = [
    'Not Executed',
    'Executed',
    'Passed',
    'Blocked',
    'Deployed'
];

const colors = [
    '#b2b9bf',
    '#ffc107',
    '#dc3545',
    '#1e88e5',
    '#20c997',
];

class attackVectorsStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attackVectors: null
        };
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.actions && this.props.actions) {
            Promise.all([
                sdk.licenses.getStatus(1),
                sdk.silverBullets.get()
            ]).then((res) => {
                if (!res[0]) {
                    return;
                }
                const licenseDetails = res[0].eyeOfTheEnemyLicense;
                const bullets = res[1];

                const licenseType = licenseDetails.licenseType;

                const vectorMap = {};

                vectors.filter(v => !v.hide).forEach(vector => {
                    vectorMap[vector.title] = vector;

                    vectorMap[vector.title].licensed = licenseDetails[vector.propName] || licenseType === 'quota';
                    if (licenseDetails[vector.propName]  || licenseType === 'quota') {
                        vectorMap[vector.title].counters = {
                            bullets: 0,
                            noStatus: 0,
                            selected: 0,
                            downloaded: 0,
                            executed: 0,
                            passed: 0,
                            blocked: 0,
                            deployed: 0
                        };
                    }
                });

                const allVectors = {
                    propName: 'allVectors',
                    title: licenseType === 'quota'? 'All Vectors' : 'All Licensed Vectors',
                    color: 'secondary',
                    licensed: true,
                    counters: {
                        bullets: 0,
                        noStatus: 0,
                        selected: 0,
                        downloaded: 0,
                        executed: 0,
                        passed: 0,
                        blocked: 0,
                        deployed: 0
                    }
                };

                bullets.forEach(bullet => {
                    if (!vectorMap[bullet.attackVector].licensed  && licenseType !== 'quota') {
                        return;
                    }
                    vectorMap[bullet.attackVector].counters.bullets++;
                    allVectors.counters.bullets++;

                    const action = this.props.actions.find(a => a.silverBulletId === bullet.id);

                    if(!action) {
                        vectorMap[bullet.attackVector].counters['noStatus']++;
                        allVectors.counters['noStatus']++;
                    } else {
                        let status;
                        if (action.defRuleStatus === 'deployed' && ['executed','passed','blocked',].includes(action.payloadStatus)) {
                            status = 'deployed';
                        } else if (action.payloadStatus !== 'discarded') {
                            status = action.payloadStatus;
                        }

                        if(status) {
                            vectorMap[bullet.attackVector].counters[status]++;
                            allVectors.counters[status]++;
                        } else {
                            vectorMap[bullet.attackVector].counters['noStatus']++;
                            allVectors.counters['noStatus']++;
                        }
                    }
                });

                const vectorArray = [
                    allVectors,
                    ...Object.values(vectorMap)
                ];
                
                vectorArray.forEach(v => {
                    if (!v.licensed && licenseType !== 'quota') {
                        return;
                    }

                    const executed = v.counters.executed + v.counters.passed + v.counters.blocked + v.counters.deployed;
                    if (licenseType === 'quota') {
                        const selected = v.counters.selected + v.counters.downloaded + executed;
                        v.progressValue = selected? Math.ceil(100 * executed /  selected) : 0;
                        v.progressText = selected? `${v.progressValue}%` : null;
                    } else {
                        v.progressValue = v.counters.bullets? Math.ceil(100 * executed /  v.counters.bullets) : 100;
                        v.progressText =`${v.progressValue}%`;
                    }
                    
                    v.chartData = [
                        v.counters.noStatus + v.counters.selected + v.counters.downloaded,
                        v.counters.executed,
                        v.counters.passed,
                        v.counters.blocked,
                        v.counters.deployed
                    ];

                    if(licenseType === 'quota') {
                        v.chartData[0] =  v.counters.selected + v.counters.downloaded;
                    }
                });

                this.setState({
                    attackVectors: vectorArray,
                    chosenVectorIndex: 0,
                    chartTitle: vectorArray[0].title + ' by status',
                    chartData: [ ...vectorArray[0].chartData]
                });

                
            });   

            
        }
    }



    render() {
        if (!this.state.attackVectors) {
            return (
                <Card body> 
                    <Row>
                        <Col>
                            <h2 className='mb-2 text-center'>
                                No Active License
                            </h2>
                        </Col>
                    </Row>
                    {/* <Row className='mb-4'>
                    <Col>
                        <h1 className='text-center'>
                            <i className='fas fa-spin fa-spinner'/>
                        </h1>
                    </Col>
                    </Row> */}
                </Card> 
            );
        }
        return (
            <Card body> 
                <Row>
                    <Col>
                        <h2 className='mb-2 text-center'>
                            Silver Bullet Status by Attack Vectors
                        </h2>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col md='4'>
                        <div className="chart-wrapper mt-1" style={{ width: '100%', margin: '0 auto', height: 400 }}>
                            <Pie
                                data={{
                                    labels,
                                    datasets: [{
                                        borderWidth: 0,
                                        backgroundColor: colors,
                                        hoverBackgroundColor: colors,
                                        data: this.state.chartData
                                    }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: { display: true, labels: { fontFamily: "Poppins", fontColor: '#ced4da' }, position: 'bottom'},
                                    title: { text: this.state.chartTitle , display: true, fontFamily: "Poppins", fontColor: '#ced4da', fontStyle: 'normal'}
                                }}
                            />
                        </div>
                    </Col>
                    <Col md='8'>
                        <Row className='pt-5'>
                            <Col md='6'>
                                {this.state.attackVectors.slice(0,8).map((vector,index) => 
                                    <Row className='mt-3 mb-3 attack-vector-row' key={`${vector.title} row`}>
                                        <Col md='6' className='align-middle'>
                                            {vector.licensed?
                                                <Button className='btn-link' block onClick={() => this.setState({
                                                    chosenVectorIndex: index,
                                                    chartTitle: this.state.attackVectors[index].title + ' by status',
                                                    chartData: this.state.attackVectors[index].chartData})}
                                                >
                                                    <h5 className={`text-center ${this.state.chosenVectorIndex === index? 'text-'+ vector.color : ''}`}>
                                                        {vector.title}
                                                    </h5>
                                                </Button>   
                                            :
                                                <div className='btn-link btn btn-secondary btn-block'>
                                                    <h5 className={'text-center text-muted'}>
                                                        {vector.title}
                                                    </h5>
                                                </div>
                                            }
                                        </Col>
                                        <Col md='6' className='pt-1'>
                                            <Progress className="align-middle" value={vector.licensed? vector.progressValue : 100} color={vector.color}>
                                                {vector.progressText !== undefined? vector.progressText : <i className='mdi mdi-lock'/>}
                                            </Progress>
                                        </Col>
                                    </Row>
                                )}                              
                            </Col>
                            <Col md='6'>
                                {this.state.attackVectors.slice(8).map((vector,index) => 
                                    <Row className='mt-3 mb-3 attack-vector-row' key={`${vector.title} row`}>
                                        <Col md='6' className='align-middle'>
                                            {vector.licensed?
                                                <Button className='btn-link' block onClick={() => this.setState({
                                                    chosenVectorIndex: index + 8,
                                                    chartTitle: this.state.attackVectors[index + 8].title + ' by status',
                                                    chartData: this.state.attackVectors[index + 8].chartData})}
                                                >
                                                    <h5 className={`text-center ${this.state.chosenVectorIndex === index + 8? 'text-'+ vector.color : ''}`}>
                                                        {vector.title}
                                                    </h5>
                                                </Button>   
                                            :
                                                <div className='btn-link btn btn-secondary btn-block'>
                                                    <h5 className={'text-center text-muted'}>
                                                        {vector.title}
                                                    </h5>
                                                </div>
                                            }
                                        </Col>
                                        <Col md='6' className='pt-1'>
                                            <Progress className="align-middle" value={vector.licensed? vector.progressValue : 100} color={vector.color}>
                                                {vector.progressText !== undefined? vector.progressText : <i className='mdi mdi-lock'/>}
                                            </Progress>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Col>               
                </Row>
            </Card>
        );
    }
}

export default attackVectorsStatus;