// AllScenarios.js
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import sdk from 'api-sdk'; // Ensure the correct import path
import ScenarioCard from '../reportForge/scenarioCard'; // Ensure the correct import path

const AllScenarios = () => {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    fetchAllScenarios(isMounted);

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, []);

  const fetchAllScenarios = async (isMounted) => {
    if (!isMounted) return;
    setLoading(true);
    try {
      // Fetch scenarios with an optional limit
      const filter = {
        // Uncomment and set limit if needed
        // limit: 20,
      };
      const response = await sdk.scenario.get(filter); // Fetch all scenarios without a filter or with the specified filter

      // Adjust based on actual response structure
      const scenariosData = Array.isArray(response)
        ? response
        : response.data || response.results || [];

      // For each scenario, fetch recommendations
      const scenariosWithDetails = await Promise.all(
        scenariosData.map(async (scenario) => {
          const recommendations = await fetchRecommendations(scenario.silverBulletId);
          return { ...scenario, recommendations };
        })
      );

      if (isMounted) {
        setScenarios(scenariosWithDetails);
      }
    } catch (error) {
      if (isMounted) {
        console.error('Error fetching all scenarios:', error);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  // Function to fetch recommendations for a scenario
  const fetchRecommendations = async (silverBulletId) => {
    try {
      const response = await sdk.silverBullets.getDefRuleIds(silverBulletId);
      const defRuleIds = response.defRuleIds || [];

      // Fetch and collect recommendations from each defRule
      const recommendations = await Promise.all(
        defRuleIds.map(async (defRuleId) => {
          const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);

          // Ensure systemName and mitigations are properly structured
          let systemName = 'Unknown';
          if (defRuleText.systemName && defRuleText.systemName.systemName) {
            systemName = defRuleText.systemName.systemName;
          }

          let mitigations = [];
          if (defRuleText.systemName && defRuleText.systemName.mitigations) {
            if (Array.isArray(defRuleText.systemName.mitigations)) {
              mitigations = defRuleText.systemName.mitigations;
            } else {
              mitigations = [defRuleText.systemName.mitigations].filter(Boolean);
            }
          }

          return { systemName, mitigations };
        })
      );

      return recommendations;
    } catch (error) {
      console.error(
        `Error fetching recommendations for silverBulletId ${silverBulletId}:`,
        error
      );
      return [];
    }
  };

  return (
    <div className="mt-5">
      <h3 className="text-center">All Available Scenarios</h3>
      {loading ? (
        <p>Loading scenarios...</p>
      ) : (
        <Row>
          {scenarios.map((scenario, index) => (
            <Col className="mb-4" md={6} lg={4} key={index}>
              <ScenarioCard scenario={scenario} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default AllScenarios;
