// ScenarioCard.js
import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import ChartImage from './ChartImage';
import './styles.css';

const ScenarioCard = ({ scenario }) => {
  return (
    <Card className="scenario-card shadow-sm h-100 d-flex flex-column">
      <CardBody className="flex-grow-1 d-flex flex-column">
        <CardTitle tag="h5" className="scenario-card-title">
          {scenario.silverBulletName}
        </CardTitle>

        {scenario.description && (
          <CardText className="scenario-card-text">
            {scenario.description}
          </CardText>
        )}

        <ChartImage silverBulletId={scenario.silverBulletId} />

        {scenario.scenarioDifficulty && (
          <CardText className="scenario-card-text">
            <strong>Difficulty:</strong> {scenario.scenarioDifficulty}
          </CardText>
        )}

        {scenario.scenarioImpact && (
          <CardText className="scenario-card-text">
            <strong>Impact:</strong> {scenario.scenarioImpact}
          </CardText>
        )}

        {scenario.tactic && (
          <CardText className="scenario-card-text">
            <strong>Tactic:</strong> {scenario.tactic}
          </CardText>
        )}

        {scenario.recommendations && scenario.recommendations.length > 0 && (
          <div className="mt-3 flex-grow-1 overflow-auto">
            <h5>Recommendations:</h5>
            {scenario.recommendations.map((recommendation, idx) => (
              <div key={idx} className="mb-2">
                <p>
                  <strong>System Name:</strong> {recommendation.systemName}
                </p>
                {recommendation.mitigations &&
                  recommendation.mitigations.length > 0 && (
                    <ul>
                      {recommendation.mitigations.map((mitigation, i) => (
                        <li key={i}>{mitigation}</li>
                      ))}
                    </ul>
                  )}
              </div>
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ScenarioCard;
