import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Input, Form, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import sdk from 'api-sdk'; // Ensure correct import path
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { saveAs } from 'file-saver';
import englishTemplate from './englishTemplate.docx'

const ReportForge = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runNumber, setRunNumber] = useState('');
    const [scenarios, setScenarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');


    useEffect(() => {
        fetchCompanies(); // Fetch companies when the component mounts
    }, []);

    const fetchCompanies = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            setCompanies(companiesData);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchScenarios = async () => {
        if (!selectedCompany || !runNumber) {
            alert('Please select a company and enter a run number');
            return;
        }

        setLoading(true);
        setScenarios([]); // Reset scenarios before fetching new ones
        setPayloads([]); // Reset payloads before fetching new ones

        try {
            // Fetch scenarios based on the selected company and run number
            const scenariosData = await sdk.companies.getScenariosByCompanyAndRunNumber(selectedCompany, runNumber);

            // Fetch all run histories
            const allRunHistories = await sdk.runHistory.getAll();

            // Filter run histories for the selected company and run number
            const runHistories = allRunHistories.filter(
                (rh) => rh.companyName === selectedCompany && rh.runNumber === runNumber
            );

            let rowCounter = 1;

            // Process each scenario
            const scenariosWithData = await Promise.all(
                scenariosData.scenarios.map(async (scenario) => {
                    // Fetch recommendations as before
                    const response = await sdk.silverBullets.getDefRuleIds(scenario.silverBulletId);
                    const defRuleIds = response.defRuleIds;

                    let recommendations = [];
                    if (Array.isArray(defRuleIds)) {
                        recommendations = await Promise.all(
                            defRuleIds.map(async (defRuleId) => {
                                const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);
                                const systemName = defRuleText.systemName.systemName || 'Unknown';
                                const mitigations = Array.isArray(defRuleText.systemName.mitigations)
                                    ? defRuleText.systemName.mitigations
                                    : [defRuleText.systemName.mitigations].filter(Boolean);

                                return { systemName, mitigations };
                            })
                        );
                    }

                    // Fetch payloads for this scenario
                    const payloadsForScenario = await sdk.silverBullets.getPayloads(scenario.silverBulletId);

                    // Find the run history entry for this scenario
                    const runHistoryEntry = runHistories.find(
                        (rh) => rh.bulletId === scenario.silverBulletId
                    );

                    const status = runHistoryEntry ? runHistoryEntry.status : 'Unknown';

                    // Create payload data for the table
                    const payloadsData = payloadsForScenario.map((payload) => ({
                        rowNumber: rowCounter++, // Use the rowCounter and increment it for each payload
                        status, // Pass/Fail from run history
                        details: '', // Empty string for "Details of the find"
                        fileFormat: payload.fileFormat,
                        scenarioName: payload.name,
                        pass: status === "Pass" ? true : false,
                        fail: status === "Fail" ? true : false
                    }));

                    // Add to overall payloads list
                    setPayloads((prevPayloads) => [...prevPayloads, ...payloadsData]);

                    return {
                        ...scenario,
                        recommendations,
                    };
                })
            );

            setScenarios(scenariosWithData);
        } catch (error) {
            console.error('Error fetching scenarios:', error);
        } finally {
            setLoading(false);
        }
    };





    const handleCompanyChange = (e) => {
        const companyName = e.target.value;
        const selected = companies.find(company => company.companyName === companyName);

        setSelectedCompany(companyName); // Set only the company name
        setSelectedCompanyId(selected ? selected.companyID : ''); // Set only the company ID if found
    };


    const handleRunNumberChange = (e) => {
        setRunNumber(e.target.value);
    };

    // Function to generate the .docx report
    const generateDocx = async () => {
        const templateResponse = await fetch(englishTemplate); // Replace with your template file path
        const templateArrayBuffer = await templateResponse.arrayBuffer();
        const zip = new PizZip(templateArrayBuffer);

        const imageModuleOptions = {
            centered: true,
            getImage: (tagValue, tagName) => {
                if (tagName === 'imageTag' || tagName === 'companyimage') {
                    // Convert base64 to Uint8Array
                    const byteCharacters = atob(tagValue);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    return byteArray;
                }
                throw new Error(`Unrecognized tag: ${tagName}`);
            },
            getSize: (img, tagValue, tagName) => {
                if (tagName === 'imageTag') {
                    return [616, 148];
                } else if (tagName === 'companyimage') {
                    return [416, 128]; // Adjust as needed
                }
                return [100, 100];
            },
        };


        const doc = new Docxtemplater()
            .attachModule(new ImageModule(imageModuleOptions))
            .loadZip(zip);

        const today = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);

        let companyImageBase64 = '';
        try {
            const response = await sdk.companies.getCompanyImageBase64(selectedCompanyId);
            console.log('Company image response:', response);
            // Adjust the property name based on the actual response
            companyImageBase64 = response.base64Image || response.imageData || response; // Choose the correct property
            if (typeof companyImageBase64 !== 'string') {
                throw new Error('companyImageBase64 is not a string');
            }
            // Remove data URI prefix if present
            if (companyImageBase64.startsWith('data:image')) {
                companyImageBase64 = companyImageBase64.split(',')[1];
            }
        } catch (error) {
            console.error('Error fetching company image:', error);
        }



        // Fetch base64 chart images and embed them in the data
        const scenariosWithImages = await Promise.all(
            scenarios.map(async (scenario) => {
                try {
                    // Fetch the chart image in base64 format
                    const { base64Chart } = await sdk.silverBullets.getChartBase64(scenario.silverBulletId); // Ensure this is correct

                    // Add the base64 chart image to the scenario object
                    return {
                        ...scenario,
                        chartImage: base64Chart // Store base64 string
                    };
                } catch (error) {
                    console.error('Error fetching base64 chart:', error);
                    return { ...scenario, chartImage: '' }; // Handle case when image is not available
                }
            })
        );

        doc.setData({
            companyName: selectedCompany,
            companyimage: companyImageBase64,
            runNumber: runNumber,
            date: formattedDate,
            scenarios: scenariosWithImages.map((scenario, index) => {
                // Determine the difficulty level flags
                const isH = scenario.scenarioDifficulty === 'High';
                const isM = scenario.scenarioDifficulty === 'Medium';
                const isL = scenario.scenarioDifficulty === 'Low';
                const ImpactH = scenario.scenarioImpact === 'High';
                const ImpactM = scenario.scenarioImpact === 'Medium';
                const ImpactL = scenario.scenarioImpact === 'Low';

                return {
                    ...scenario,
                    scenarioNumber: index + 1,
                    scenarioTitle: scenario.silverBulletName,
                    scenarioDescription: scenario.description,
                    attackFlow: [{ imageTag: scenario.chartImage }],
                    recommendations: scenario.recommendations,
                    // Include the difficulty level flags
                    isH,
                    isM,
                    isL,
                    ImpactH,
                    ImpactM,
                    ImpactL,
                };
            }),
            payloads,
        });


        try {
            doc.render();
        } catch (error) {
            console.error('Error rendering document:', error);
        }

        const output = doc.getZip().generate({ type: 'blob' });
        saveAs(output, `${selectedCompany}_${runNumber}_Report.docx`);
    };



    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-sm border-0">
                        <CardBody>
                            <CardTitle tag="h4" className="text-center mb-4">
                                Report Forge
                            </CardTitle>
                            <Form>
                                <FormGroup>
                                    <Label for="companySelect">Select Company</Label>
                                    <Input
                                        type="select"
                                        id="companySelect"
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                    >
                                        <option value="">Choose a company</option>
                                        {companies.map((company) => (
                                            <option key={company.companyID} value={company.companyName}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="runNumber">Run Number</Label>
                                    <Input
                                        type="text"
                                        id="runNumber"
                                        placeholder="Enter run number"
                                        value={runNumber}
                                        onChange={handleRunNumberChange}
                                    />
                                </FormGroup>
                                <Button color="primary" block onClick={fetchScenarios} disabled={loading}>
                                    {loading ? 'Loading...' : 'Fetch Scenarios'}
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    {scenarios && scenarios.length > 0 ? (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardTitle tag="h5" className="text-center">
                                    Scenarios for Company: {selectedCompany} | Run Number: {runNumber}
                                </CardTitle>
                                {scenarios.map((scenario, index) => (
                                    <div key={index} className="mb-4">
                                        <CardText className="text-muted">
                                            {scenario.silverBulletName} - {scenario.description}
                                        </CardText>
                                        {/* Display the chart image */}
                                        <ChartImage silverBulletId={scenario.silverBulletId} />
                                        {/* Display recommendations */}
                                        {scenario.recommendations && scenario.recommendations.length > 0 && (
                                            <div className="mt-3">
                                                <h5>Recommendations:</h5>
                                                {scenario.recommendations.map((recommendation, idx) => (
                                                    <div key={idx} className="mb-2">
                                                        <p><strong>System Name:</strong> {recommendation.systemName}</p>
                                                        {recommendation.mitigations && recommendation.mitigations.length > 0 && (
                                                            <ul>
                                                                {recommendation.mitigations.map((mitigation, i) => (
                                                                    <li key={i}>{mitigation}</li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <Button color="success" onClick={generateDocx}>
                                    Generate Report
                                </Button>
                            </CardBody>
                        </Card>
                    ) : (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardText className="text-center">
                                    No scenarios found for the selected company and run number.
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

// Component to fetch and display the chart image
const ChartImage = ({ silverBulletId }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            const url = await sdk.silverBullets.getChartLink(silverBulletId);
            setImageUrl(url);
        };
        fetchImage();
    }, [silverBulletId]);

    return (
        <>
            {imageUrl ? (
                <img src={imageUrl} alt={`Chart for SilverBullet ${silverBulletId}`} className="img-fluid" />
            ) : (
                <p>Chart image not available</p>
            )}
        </>
    );
};

export default ReportForge;
