import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/buttons/deleteButton';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';


class TechniqueManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      techniques: null,
    };

    this.getTechniques = this.getTechniques.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    
  }

  getTechniques() {
    sdk.techniques.get().then(techniques => {
        this.setState({ techniques });
    });
  }

  async handleDelete(id) {
    try {
      await sdk.techniques.delete(id);
      this.getTechniques();
      toast.success('Technique deleted successfully');
    } catch (error) {
      console.error('Error deleting technique:', error);
      toast.error('There was an error deleting the technique');
      // You can also add a user-friendly notification or message here
    }
  }
  

  componentDidMount() {
    this.getTechniques();
  }

  render() {
    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Technique Management
          <span className="float-right">
            <Link to={`technique-management/create`} onClick={e => e.stopPropagation()}>
              <Button className='btn-outline-secondary' size='sm'>
                Create Technique
              </Button>
            </Link>
          </span>
        </CardTitle>
        <CardBody className='p-0'>
          {this.state.techniques?
            <Table hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Mitre Id</th>
                  <th>Name</th>
                  <th>Attack Vector</th>
                  <th>Sub</th>
                  <th>Created</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.techniques.length === 0?
                  <tr key='no-technique'>
                    <td colpan='5'>
                      <h3>
                        No Techniques
                      </h3>
                    </td>
                  </tr>
              : null}
              {
                this.state.techniques.map((technique, i) => (
                  <tr key={technique.id}>
                    <td>{technique.id}</td>
                    <td>{technique.mitreId}</td>
                    <td>{technique.name}</td>
                    <td>{technique.attackVector}</td>
                    <td>{technique.isSubTechnique? <i className='fas fa-check'/> : ''}</td>
                    <td>{(new Date(Date.parse(technique.createdAt))).toLocaleString('en-il')}</td>
                    <td>
                        <Link to={`technique-management/edit/${technique.id}`} onClick={e => e.stopPropagation()}>
                          <Button className='btn-outline-secondary'>
                            <i className='mdi mdi-pencil'/>
                          </Button>
                        </Link>
                    </td>
                    <td><DeleteButton item='technique' deleteFunc={() => {this.handleDelete(technique.id)}}/></td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          : 
            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
          }
        </CardBody>
      </Card>
    );
  }
}

export default TechniqueManagement;