import React from 'react';
import {
    Badge,
    Button,
    Jumbotron,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import sdk from 'api-sdk';
import PdfEditor from './pdfEditor';
import PdfDataViewer from '../../../components/pdfDataViewer';
import EDRs from '../../../assets/data/edrs.json'

class DefRuleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defRules: null,
            viewedDocId: null,
            editMode: false, // State to handle edit mode
        };
        this.edrTagMap = {};
        EDRs.forEach(tag => {
            this.edrTagMap[tag.propName] = tag.name;
        });
    }

    componentDidMount() {
        if (this.props.bullet) {
            sdk.silverBullets.getDefRules(this.props.bullet.id, { include: 'edrTag' })
                .then(defRules => this.setState({ defRules, viewedDocId: null }));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.bullet && (!prevProps.bullet || this.props.bullet.id !== prevProps.bullet.id)) {
            sdk.silverBullets.getDefRules(this.props.bullet.id, { include: 'edrTag' })
                .then(defRules => this.setState({ defRules, viewedDocId: null }));
        } else if (!this.props.isOpen && prevProps.isOpen) {
            this.setState({ viewedDocId: null, editMode: false }); // Reset edit mode when modal closes
        }
    }

    handleEdit = () => {
        this.setState({ editMode: true }); // Enable edit mode when user clicks "Edit"
    };

    handleExitEdit = () => {
        this.setState({ editMode: false, viewedDocId: null }); // Exit edit mode
    };

    handleSave = (blob) => {
        // const { bullet } = this.props;
        const { viewedDocId } = this.state;

        // Upload the modified PDF blob to the server
        const formData = new FormData();
        formData.append('file', blob, 'modified.pdf');

        sdk.defRules.uploadFile(blob, viewedDocId)
            .then(response => {
                console.log('File uploaded successfully', response);
                this.handleExitEdit(); // Exit edit mode after saving
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    };

    render() {
        const { bullet } = this.props;
        const { editMode } = this.state;

        if (!bullet) return null; // Ensure bullet is defined before rendering

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={this.state.viewedDocId ? 'modal-dialog-centered modal-wide' : 'modal-dialog-centered'}
                data-theme='dark'
            >
                <ModalHeader toggle={() => {
                    if (this.state.viewedDocId) {
                        this.setState({ viewedDocId: null, editMode: false });
                    } else {
                        this.props.toggle();
                    }
                }}>
                    {!this.state.viewedDocId ? 'Choose Defence Rule' : (editMode ? 'Edit Defence Rule' : 'Defence Rule Preview')}
                </ModalHeader>

                {!this.state.defRules ?
                    <h1 className='text-center'><i className='fas fa-spin fa-spinner' /></h1>
                    : (!this.state.viewedDocId ?
                        <ModalBody className='modal-menu'>
                            {this.state.defRules.map(defRule =>
                                <Jumbotron className='menu-entry' key={`defRule-${bullet.id}-${defRule.id}`}>
                                    <div className='info-container'>
                                        <div className='title-row title-test '>
                                            <h3>{defRule.name}</h3>
                                        </div>
                                        <h3 className='tags-row'>
                                            <Badge className='pl-2 pr-2 ml-1 mr-1 mt-1' color="info">{`ID: ${defRule.id}`}</Badge>
                                            <Badge className='pl-2 pr-2 ml-1 mr-1 mt-1' color="info">
                                                {defRule.edrTag ? (this.edrTagMap[defRule.edrTag.edrName] || defRule.edrTag.edrName) : null}
                                            </Badge>
                                        </h3>
                                    </div>
                                    <div className='button-container'>
                                        <Button onClick={() => sdk.silverBullets.downloadDefRule(bullet.id, defRule.id, defRule.fileName).then(() => this.props.updateFunc(bullet.id))}>
                                            Download
                                        </Button>
                                        {defRule.fileName && defRule.fileName.endsWith('.pdf') ?
                                            <div>
                                                <Button
                                                    onClick={() => this.setState({ viewedDocId: defRule.id })}
                                                >
                                                    Preview
                                                </Button>
                                                {/* <Button
                                                    color="warning"
                                                    onClick={() => this.setState({ viewedDocId: defRule.id, editMode: true })}
                                                >
                                                    Edit
                                                </Button> */}
                                            </div>
                                            : null}
                                    </div>
                                </Jumbotron>
                            )}
                        </ModalBody>
                        :
                        editMode ?
                            // If in edit mode, render PdfEditor
                            <PdfEditor
                                getData={() => sdk.silverBullets.getDefRuleFile(bullet.id, this.state.viewedDocId)} // Fetch arraybuffer from SDK
                                exitEditMode={this.handleExitEdit}
                                onSave={this.handleSave} // Handle saving the modified PDF
                            />
                            :
                            // If not in edit mode, render PdfDataViewer for preview
                            <PdfDataViewer
                                getData={() => sdk.silverBullets.getDefRuleFile(bullet.id, this.state.viewedDocId).then(data => {
                                    this.props.updateFunc(bullet.id);
                                    return data;
                                })}
                            />
                    )
                }
            </Modal>
        );
    }
}

export default DefRuleModal;
