import React, { useState, useEffect } from 'react';
import sdk from 'api-sdk';

const ChartImage = ({ silverBulletId }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    let isMounted = true;

    const fetchImage = async () => {
      try {
        const url = await sdk.silverBullets.getChartLink(silverBulletId);
        if (isMounted) {
          setImageUrl(url);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching chart image:', error);
        }
      }
    };

    fetchImage();

    return () => {
      isMounted = false;
    };
  }, [silverBulletId]);

  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={`Chart for SilverBullet ${silverBulletId}`}
          className="img-fluid"
        />
      ) : (
        <p>Chart image not available</p>
      )}
    </>
  );
};

export default ChartImage;
